import 'bootstrap/dist/css/bootstrap.min.css';
import 'semantic-ui-css/semantic.min.css';
import 'animate.css';
import './App.css';
import './BGround.css';
import {isMobile} from 'react-device-detect';
import ReactPlayer from 'react-player'
import LOGO from './assets/logos/FREGST_DEV_LOGO_ALPHA.png';

import ICON_GAME from './assets/icons/ICON_GAME.png';
import ICON_SOFT from './assets/icons/ICON_SOFT.png';
import ICON_TECH from './assets/icons/ICON_TECH.png';

import BA_LOGO from './assets/logos/games/BA_LOGO.png';
import CO_LOGO from './assets/logos/games/OC_LOGO.png';
import VR_LOGO from './assets/logos/software/VR_LOGO.png';
import MAPP_LOGO from './assets/logos/software/MAPP_LOGO.png';
import IA_LOGO from './assets/logos/software/IA_LOGO.png';
import WEB_LOGO from './assets/logos/software/WEB_LOGO.png';
import ARD_LOGO from './assets/logos/tech/ARD_LOGO.png';
import P3D_LOGO from './assets/logos/tech/3D_LOGO.png';

import PRINT_1 from './assets/img/PRINT1.jpg';
import PRINT_2 from './assets/img/PRINT2.jpg';
import PRINT_3 from './assets/img/PRINT3.jpg';

import ARD_1 from './assets/img/ARD_IMG_1.png';
import ARD_2 from './assets/img/ARD_IMG_2.png';
import ARD_3 from './assets/img/ARD_IMG_3.png';

import { Button, Container, Grid, GridColumn, Icon, Image, Modal, ModalActions, ModalContent } from 'semantic-ui-react';
import { useState } from 'react';
import { Carousel } from 'react-bootstrap';

function App() {

  const [openModIMG, setOpenModIMG] = useState(false);
  const [openModMP, setOpenModMP] = useState(false);
  const [modalPlayerURL, setModalPlayerURL] = useState(false);
  const [modalImgSrcs, setModalImgSrcs] = useState([]);

  function OpenModalPlayer(url)
  {
    setModalPlayerURL(url);
    setOpenModMP(true);
  }

  function OpenModalIMG(srcs)
  {
    setModalImgSrcs(srcs);
    setOpenModIMG(true);
  }

  return (
    <div className="App">
      <div class='light x1'></div>
      <div class='light x2'></div>
      <div class='light x3'></div>
      <div class='light x4'></div>
      <div class='light x5'></div>
      <div class='light x6'></div>
      <div class='light x7'></div>
      <div class='light x8'></div>
      <div class='light x9'></div>
      <Container fluid>
        <Grid centered columns={1}>
          <GridColumn className='animate__animated animate__fadeInDown'>
            <Image className='gradient-border' centered src={LOGO} size='medium'/>
          </GridColumn>
        </Grid>
        <Grid centered columns={1}>
          <GridColumn className='white text-center fs-2'>
            <p className='animate__animated animate__fadeIn animate__delay-1s'>
              Más de 12 Años Desarrollando Proyectos Sorprendentes
            </p>
          </GridColumn>
        </Grid>
        <Grid centered stackable columns={3}>
          <GridColumn className='white text-center animate__animated animate__fadeInLeft animate__delay-2s'>
            <Image centered src={ICON_GAME} size='small'/>
            <p className='fs-3'>
              VIDEOJUEGOS
            </p>
            <div className='w-150p m-auto'>
              <a href='https://bunnyadv.fregstdev.com/' target='_blank' rel='noreferrer'>
               <Image centered src={BA_LOGO} rounded size='small' className='grow'/>
              </a>
              <div className='mt-2'>
                <span className='fs-5'>Bunny Adventures</span>
              </div>
              <br></br>
              <a href='https://ordersquest.fregstdev.com/' target='_blank' rel='noreferrer'>
               <Image centered src={CO_LOGO} rounded size='small' className='grow'/>
              </a>
              <div className='mt-2'>
                <span className='fs-5'>Order´s Quest</span>
              </div>
            </div>
          </GridColumn>
          <GridColumn className='white text-center animate__animated animate__fadeInUp animate__delay-2s'>
            <Image centered src={ICON_SOFT} size='small'/>
            <p className='fs-3'>
              SOFTWARE
            </p>
            <div className='m-auto'>
              <Grid centered columns={2}>
                <GridColumn>
                  <Image centered src={WEB_LOGO} rounded size='small' className='grow pointer'
                      onClick={() => OpenModalPlayer("https://youtu.be/8mODAoQtoW4")}/>
                  <div className='mt-2 text-center'>
                    <span className='fs-5'>Apps WEB y PC</span>
                  </div>
                </GridColumn>
                <GridColumn>
                  <Image centered src={MAPP_LOGO} rounded size='small' className='grow pointer' 
                      onClick={() => OpenModalPlayer("https://youtu.be/M_F9ReKs-D4")}/>
                  <div className='mt-2 text-center'>
                    <span className='fs-5'>Apps Mobiles</span>
                  </div>
                </GridColumn>
              </Grid>
              <Grid centered columns={2} className='mt-0'>
                <GridColumn>
                  <Image centered src={VR_LOGO} rounded size='small' className='grow pointer' 
                    onClick={() => OpenModalPlayer("https://youtu.be/7ZJcoAWg9aw?si=Xg2o9gCF26HwI4MJ&t=21")}/>
                  <div className='mt-2 text-center'>
                    <span className='fs-5'>Realidad Virtual</span>
                  </div>
                </GridColumn>
                <GridColumn>
                  <Image centered src={IA_LOGO} rounded size='small' className='grow pointer'
                    onClick={() => OpenModalPlayer("https://youtu.be/Ac0l9SXpuYA")}/>
                  <div className='mt-2 text-center'>
                    <span className='fs-5'>Inteligencia artificial</span>
                  </div>
                </GridColumn>
              </Grid>
            </div>
          </GridColumn>
          <GridColumn className='white text-center animate__animated animate__fadeInRight animate__delay-2s'>
            <Image centered src={ICON_TECH} size='small'/>
            <p className='fs-3'>
              TECNOLOGIA
            </p>
            <div className='w-150p m-auto'>
              <Image centered src={ARD_LOGO} rounded size='small' className='grow pointer' onClick={() => OpenModalIMG([ARD_1,ARD_2,ARD_3])}/>
              <div className='mt-2'>
                <span className='fs-5'>Arduino</span>
              </div>
              <br></br>
              <Image centered src={P3D_LOGO} rounded size='small' className='grow pointer' onClick={() => OpenModalIMG([PRINT_1,PRINT_2,PRINT_3])}/>
              <div className='mt-2'>
                <span className='fs-5'>Impresión 3d</span>
              </div>
            </div>
          </GridColumn>
        </Grid>
        <Grid centered columns={1} className='mt-0'>
          <GridColumn className='text-center animate__animated animate__fadeInUp animate__delay-4s'>
            <Button inverted color='green' href="mailto:moiseslopez@frenzygamesstd.com" className='m-auto'>
              <Icon name='mail' />
              Contactar
            </Button>&nbsp;&nbsp;
            <Button inverted color='blue' href="https://www.fregstdev.com/docs/Lineamientos_Contrato.pdf" target='_blank' rel='noreferrer' className='m-auto'>
              <Icon name='file alternate' />
              Lineamientos
            </Button>
          </GridColumn>
        </Grid>
        {isMobile ? <div style={{height: '150px'}}></div> : <></>}
      </Container>
      <ModalIMGViewer open={openModIMG} setOpenModIMG={(open) => setOpenModIMG(open)} srcs={modalImgSrcs}/>
      <ModalMediaPlayer open={openModMP} setOpenModMP={(open) => setOpenModMP(open)} url={modalPlayerURL}/>
    </div>
  );
}

function ModalIMGViewer(props)
{
  const listImgs = props.srcs.map((img, index) =>
    <Carousel.Item index={index}>
          <Image src={img} className="d-block w-100"/>
    </Carousel.Item>
  );

  return (
    <Modal
      basic
      onClose={() => props.setOpenModIMG(false)}
      onOpen={() => props.setOpenModIMG(true)}
      open={props.open}
      size='small'
    >
      <ModalContent>
      <Carousel>
        {listImgs}
      </Carousel>
      </ModalContent>
      <ModalActions>
        <Button color='red' inverted onClick={() => props.setOpenModIMG(false)}>
          <Icon name='close' /> Cerrar
        </Button>
      </ModalActions>
    </Modal>
  );
}

function ModalMediaPlayer(props)
{
  return (
    <Modal
      basic
      onClose={() => props.setOpenModMP(false)}
      onOpen={() => props.setOpenModMP(true)}
      open={props.open}
      size='small'
    >
      <ModalContent>
        <ReactPlayer url={props.url} width='100%'/>
      </ModalContent>
      <ModalActions>
        <Button color='red' inverted onClick={() => props.setOpenModMP(false)}>
          <Icon name='close' /> Cerrar
        </Button>
      </ModalActions>
    </Modal>
  );
}

export default App;
